import React from 'react'

export default function NoData({ chartTitle, noMargin }){
  return (
    <div className='card-content'>
      <div className='card-title headline'>
        { chartTitle }
      </div>
      <div className={`card-body ${!noMargin ? 'm-t-20' : ''}`} style={ !chartTitle ? { paddingTop: 20 } : {} }>
        <div style={{minHeight: '180px' }}>
          <div className='flex-box items-center content-center flex-column' style={{ height:'100%'}}>
            <i className='fa-light fa-chart-scatter-bubble large' style={{ fontSize: 30 }}/>
            <div style={{ fontSize: 15, marginTop: 10, textAlign: 'center' }}>
              { 'No data' }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
