import React, { useState, useEffect, useRef } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import XYChart from '../shared/charts/XYChart'
import DropDownMenu from '../shared/DropDownMenu'
import GlobalLoader from '../shared/GlobalLoader'
import FlashMessage from '../shared/FlashMessage'
import 'react-dates/initialize'
import CreateButton from '../shared/CreateButton'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { truncateString } from '../utils'

export default function Report({
  appProps, report, chartColors, breadcrumbs, exportReportPath, allowSearch, pastDaysLimit, hideDateRangePicker
}) {
  const UPPER_RANGE_LIMIT_IN_MS = pastDaysLimit && (pastDaysLimit + 1) * 24 * 60 * 60 * 1000
  const MAX_DATE_RANGE_DAYS = 92
  const MAX_DATE_RANGE_MS = MAX_DATE_RANGE_DAYS * (24 * 60 * 60 * 1000)
  const DEFAULT_INITIAL_DAYS = 31

  const [dateRange, setDateRange] = useState({
    start_date: moment().subtract(report.initialDates !== undefined ? report.initialDates : DEFAULT_INITIAL_DAYS, 'days'),
    end_date: moment(new Date())
  })
  const [chartData, setChartData] = useState([])
  const [completeListData, setCompleteListData] = useState([])
  const [listData, setListData] = useState([])
  const [listTotalPages, setListTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [focusedInput, setFocusedInput] = useState(null)
  const smallDevice = window.matchMedia('(max-width: 480px)').matches
  const flashMessageRef = useRef(null)
  const didFetchRef = useRef(null)
  const isMobileApp = window.location.search.includes('origin=mobileApp')

  useEffect(()=> {
    fetchReportData()
  }, [])

  function fetchReportData(){
    setLoading(true)
    let url = `${report.fetchDataPath}?date_range=${JSON.stringify(dateRange)}&key=${report.key}`

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: res => {
        setListTotalPages(res.totalPages)
        setListData(res.items)
        setCompleteListData(res.extraData)

        setChartData(res.data.map(item => ({
          category: ['merchant_title', 'merchant_subaccount_name', 'ticket_title', 'terminal_title', 'location', 'region', 'card_type', 'bank_name'].includes(report.xAxis) ? truncateString(item.category, 20) : item.category,
          [report.yAxis]: item.gross_sales
        })))
      },
      complete: res => {
        didFetchRef.current = Math.random()
        setLoading(false)
      }
    })
  }

  function itemRenderer(item, columns) {
    return (
      <>
        { columns.map( column => {
            const truncatableColumn = ['merchantTitle', "merchantSubaccountName", "title", "deviceName", "location", "region", "cardType", "bankName"].includes(column.label)
            return(
              <div key={ column.label } className='item flex-1'>
                { truncatableColumn ? truncateString(item[column.label], 20) : item[column.label] }
              </div>
            )
        })}
      </>
    )
  }

  function handleApplyDateRange() {
    setFocusedInput(null)
    if (dateRange.end_date - dateRange.start_date > MAX_DATE_RANGE_MS){
      flashMessageRef.current.show(Website.translations.tooLongDateRange, 'error')
    } else {
      fetchReportData()
    }
  }

  function ConfirmButton() {
    return(
      <div className='flex-box content-end'>
        <button className='btn m-r-10 m-b-10' onClick={ handleApplyDateRange }>
          { Website.translations.apply }
        </button>
      </div>
    )
  }

  function handleExportReport(){
    let fd = new FormData()
    fd.append('list_data', JSON.stringify(completeListData))
    fd.append('start_date', dateRange.start_date.format('DD/MM/YYYY'))
    fd.append('end_date', dateRange.end_date.format('DD/MM/YYYY'))

    Rails.ajax({
      type: 'POST',
      data: fd,
      url: exportReportPath,
      dataType: 'json',
      success: res => {
        flashMessageRef.current.show(Website.translations.exportStarted, 'success')
      },
      error: res => {

      }
    })
  }

  const onDateChange = ({ startDate, endDate }) => {
    if(startDate <= endDate) {
      setDateRange({
        ...dateRange,
        end_date: moment(endDate),
        start_date: moment(startDate)
      })
    }
  }

  return(
    <>
      <CreateButton
        pageTitle={ Website.translations.dashboard }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div className='page-wrapper-header flex-box content-space-between'>
        <div className='page-header'>
          <h1 className='page-header-title'>
            { report.title }
          </h1>
        </div>
        <div className='flex-box items-center'>
          <div style={{ marginRight: '15px' }}>
            { !hideDateRangePicker &&
              <DateRangePicker
                displayFormat={() => "DD MMM YYYY"}
                endDate={ dateRange.end_date }
                endDateId="endDate"
                focusedInput={focusedInput}
                onDatesChange={onDateChange}
                onFocusChange={setFocusedInput}
                orientation={smallDevice ? 'vertical' : 'horizontal'}
                readOnly={ true }
                required={ true }
                noBorder={ true }
                startDate={ dateRange.start_date }
                startDateId="startDate"
                isOutsideRange={ date => ((date > new Date() - 1) || (pastDaysLimit && date < new Date() - UPPER_RANGE_LIMIT_IN_MS)) }
                minimumNights={ 0 }
                firstDayOfWeek={ 1 }
                renderCalendarInfo={ ConfirmButton }
                hideKeyboardShortcutsPanel
                keepOpenOnDateSelect
              />
            }
          </div>

          <DropDownMenu
            items={ [{ icon: 'fa-file-excel', action: handleExportReport, text: Website.translations.exportToExcel, permissions: true }] }
            title={ Website.translations.dropMenuAction }
          />
        </div>
      </div>
      { chartData.length > 0 &&
        <div className='card'>
          <div className='card-content'>
            <div className='card-body'>
              { report.type === "bar" &&
                <XYChart
                  data={ chartData }
                  seriesLabels={ [{ gross_sales: Website.translations.grossSales }] }
                  xAxisTitle={ '' }
                  yAxisTitle={ '' }
                  columnWidth={ 15 }
                  chartColors={ chartColors }
                  chartKey={ "chartdiv1" }
                />
              }
            </div>
          </div>
        </div>
      }

      <DynamicEntityIndex
        key={ didFetchRef.current }
        initialItems={ listData }
        setInitialItems={ setListData }
        initialTotalPages={ listTotalPages }
        itemsPath={ report.fetchDataPath }
        tabs={ [] }
        tableHeadings={ report.columns }
        itemRenderer={ itemRenderer }
        urlParams={ `date_range=${JSON.stringify(dateRange)}&key=${report.key}` }
        noResourcesTitle={ Website.translations.noData }
        icon={ 'fa-chart-scatter-bubble' }
        setExtraData={ setCompleteListData }
        disableDelete
        disableSearch={ !allowSearch }
        disableReload
        disableUrlUpdates
      />

      { loading && <GlobalLoader fullPage/> }

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}
