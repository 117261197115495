import React from 'react'
import ReactDOM from 'react-dom'
import FlashMessages from './FlashMessages'

const pica = require('pica')()

export default class MediaUploader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      files: [],
      progress: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.files.length && this.state.files.length) {
      this.uploadMedia()
    }
  }

  pushFileToUpload(file) {
    setTimeout( () => this.setState({ files: [...this.state.files, file] }), 100 ) // wait for the state to change when pushing multiple files quickly
  }

  isUploading(mediaSourceId = null) {
    if (mediaSourceId) {
      return !!this.state.files.find( (file, index) => file.mediaSourceId === mediaSourceId && index >= this.state.progress )
    }

    return !!this.state.files.length
  }

  isImageType(type) {
    return type.startsWith('image') && !type.includes('gif')
  }

  async convertImage(file) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    let fileType = 'image/webp'

    // On Safari we default to image/png so we can maintain any transparency
    // For jpegs we do not change the format since jpegs do not support transparency and converting to png would result in bigger file size
    if (isSafari) {
      fileType = file.blob.type === 'image/jpeg' ? 'image/jpeg' : 'image/png'
    }

    const image = new Image()
    image.src = file.source

    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height

    const resizedImage = await pica.resize(image, canvas)
    const blob = await pica.toBlob(resizedImage, fileType)

    // We add path and name properties that reflect the new file extention after the conversion
    blob.path = `${ file.blob.path.split('.').slice(0, -1).join('.') }.${ fileType.split('/')[1] }`
    blob.name = `${ file.blob.name.split('.').slice(0, -1).join('.') }.${ file.blob.type.split('/')[1] }`

    return { ...file, blob: blob }
  }

  async uploadMedia(progress = this.state.progress) {
    const file = this.state.files[progress]

    if (file) {
      if (this.isImageType(file.blob.type)) {
        let mediaId = null
        const convertedFile = await this.convertImage(file)

        if (this.props.mediaGalleryProps.googleCloudAccessToken) {
          mediaId = await this.validateAndUploadFile(convertedFile, progress, mediaId)

        } else {
          mediaId = await this.createMediaFile(convertedFile, progress, mediaId)
        }
      } else if (this.props.mediaGalleryProps.googleCloudAccessToken) {
        this.validateAndUploadFile(file, progress)

      } else {
        this.createMediaFile(file, progress)
      }
    }
  }

  async createMediaFile(file, progress, mediaId = null) {
    const fd = new FormData()
    fd.append('file', file.blob)

    if (this.isImageType(file.blob.type)) {
      fd.append('file_name', file.blob.name)
      fd.append('media_id', mediaId)
    }

    return new Promise( (resolve, reject) => {
      Rails.ajax({
        type: 'POST',
        url: this.props.mediaPath,
        dataType: 'json',
        data: fd,
        success: result => {
          file.onUpload({ ...file, ...result.media, uploadUid: file.uid })
          resolve(result.media.mediaId)
        },
        error: result => {
          let target = document.getElementById('flash-message-ajax-container')
          if (result.error && target) {
            ReactDOM.render(<FlashMessages flashMessage={{ failure: result.error }}/>, target)
          }
        },
        complete: result => {
          if (progress < (this.state.files.length - 1)) {
            this.setState({ progress: progress + 1 })
            this.uploadMedia(progress + 1)
          } else {
            this.setState({ files: [], progress: 0 })
          }
        }
      })
    })
  }

  async validateAndUploadFile(file, progress, mediaId = null) {
    const fileSize = file.blob.size
    const filePath = file.blob.path

    const fd = new FormData()
    fd.append('file_size', fileSize)
    fd.append('file_path', filePath)

    return new Promise( (resolve, reject) => {
      Rails.ajax({
        type: 'POST',
        url: this.props.validateFilePath,
        dataType: 'json',
        data: fd,
        success: async result => {
          mediaId = await this.uploadFileToBucket(file, progress, result.accessToken, mediaId)
          resolve(mediaId)
        },
        error: result => {
          let target = document.getElementById('flash-message-ajax-container')
          if (result.error && target) {
            ReactDOM.render(<FlashMessages flashMessage={{ failure: result.error }}/>, target)
          }
        }
      })
    })
  }

  async uploadFileToBucket(file, progress, accessToken = this.props.mediaGalleryProps.googleCloudAccessToken, mediaId = null) {
    const fileName = file.blob.name
    const fileType = file.blob.type
    const bucketName = this.props.mediaGalleryProps.googleCloudBucket

    const reader = new FileReader()
    reader.readAsArrayBuffer(file.blob)

    return new Promise( (resolve, reject) => {
      reader.addEventListener("load", async (event) => {
        const bytes = event.target.result
        let response = await fetch(
          `https://storage.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=media&name=${encodeURI(this.props.mediaGalleryProps.filePath + '/' + fileName)}&predefinedAcl=publicRead`,
          {
            method: "POST",
            headers: {
              "Content-Type": fileType,
              "Authorization": `Bearer ${accessToken}`
            },
            body: bytes
          }
        )

        let result = await response.json()
        if (result.mediaLink) {
          const fileInfo = {
            file_name: result.name,
            original_file_name: result.name,
            source_url: `https://storage.googleapis.com/${bucketName}/${result.name}`,
            content_type: result.contentType,
            byte_size: parseInt(result.size)
          }

           mediaId = await this.createMediaEntry(file, fileInfo, progress, mediaId)
           resolve(mediaId)

        } else if(result.error && result.error.code === 401) {
          let target = document.getElementById('flash-message-ajax-container')
          if (result.error && target) {
            ReactDOM.render(<FlashMessages flashMessage={{ failure: 'Failed to upload. Please refresh your page.' }}/>, target)
          }
        }
      })
    })
  }

  async createMediaEntry(file, data, progress, mediaId = null) {
    const fd = new FormData()
    fd.append('file[file_name]', file.blob.name)
    fd.append('file[original_file_name]', file.blob.name)
    fd.append('file[source_url]', data.source_url)
    fd.append('file[content_type]', data.content_type)
    fd.append('file[byte_size]', data.byte_size)
    fd.append('file[media_id]', mediaId)

    return new Promise( (resolve, reject) => {
      Rails.ajax({
        type: 'POST',
        url: this.props.createMediaNoUploadPath,
        dataType: 'json',
        data: fd,
        success: result => {
          file.onUpload({ ...file, ...result.media, uploadUid: file.uid })

          resolve(result.media.mediaId)
        },
        error: result => {
          let target = document.getElementById('flash-message-ajax-container')
          if (result.error && target) {
            ReactDOM.render(<FlashMessages flashMessage={{ failure: result.error }}/>, target)
          }
        },
        complete: result => {
          if (progress < (this.state.files.length - 1)) {
            this.setState({ progress: progress + 1 })
            this.uploadMedia(progress + 1)
          } else {
            this.setState({ files: [], progress: 0 })
          }
        }
      })
    })
  }

  render() {
    const { files, progress } = this.state

    if (files.length) {
      return (
        <div className='media-uploader-wrapper'>
          <div className='media-uploader-title'>
            { Website.translations.media.uploading } { progress + 1 }/{ files.length }
          </div>

          <div className='media-uploader-bar'>
            <div
              className='media-uploader-progress background-color-scheme'
              style={{ width: `${ ((progress + 1) / files.length) * 100 }%` }}
            />
          </div>
        </div>
      )

    } else {
      return null
    }
  }
}
