import React, { useState, useEffect, useRef } from 'react'
import CreateButton from '../shared/CreateButton'
import XYChart from '../shared/charts/XYChart'
import SlicedChart from '../shared/charts/SlicedChart'
import NoData from '../shared/charts/NoData'
import GlobalLoader from '../shared/GlobalLoader'
import FlashMessage from '../shared/FlashMessage'
import { truncateString } from '../utils'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import moment from 'moment'

export default function Index({ appProps, chartColors, fetchDashboardInfoPath, reports }) {
  const MAX_DATE_RANGE_DAYS = 92
  const MAX_DATE_RANGE_MS = MAX_DATE_RANGE_DAYS * (24 * 60 * 60 * 1000)
  const DEFAULT_INITIAL_DAYS = 31

  const [salesByMerchant, setSalesByMerchant] = useState([])
  const [salesBySubmerchant, setSalesBySubmerchant] = useState([])
  const [salesByTicket, setSalesByTicket] = useState([])
  const [salesByTerminal, setSalesByTerminal] = useState([])
  const [salesByStatus, setSalesByStatus] = useState([])
  const [dateRange, setDateRange] = useState({
    start_date: moment().subtract(DEFAULT_INITIAL_DAYS, 'days'),
    end_date: moment(new Date())
  })
  const [focusedInput, setFocusedInput] = useState(null)
  const [loading, setLoading] = useState(false)
  const flashMessageRef = useRef(null)
  const smallDevice = window.matchMedia('(max-width: 480px)').matches

  const tableHeadings = [ Website.translations.columns.title ]

  function itemRenderer(item){
    return(
      <>
        <td><a href={ item.path }>{ item.title }</a></td>
      </>
    )
  }

  useEffect( () => {
    fetchData()
  },[])

  function handleApplyDateRange() {
    setFocusedInput(null)
    if (dateRange.end_date - dateRange.start_date > MAX_DATE_RANGE_MS){
      flashMessageRef.current.show(Website.translations.tooLongDateRange, 'error')
    } else {
      fetchData()
    }
  }

  function ConfirmButton() {
    return(
      <div className='flex-box content-end'>
        <button className='btn m-r-10 m-b-10' onClick={ handleApplyDateRange }>
          { Website.translations.apply }
        </button>
      </div>
    )
  }

  // function prepareDataForBarChart(data, xAxis, yAxis){
  //   let xValues = []
  //   const finalData = data.map(item => {
  //     let xValue = truncateString(item[xAxis], 20)
  //     while (xValues.includes(xValue)){
  //       xValue = xValue + " "
  //     }
  //     xValues.push(xValue)
  //     return({
  //       category: xValue,
  //       [yAxis]: item[yAxis]
  //     })
  //   })
  //   return finalData
  // }

  function fetchData() {
    setLoading(true)

    Rails.ajax({
      type: 'GET',
      url: `${fetchDashboardInfoPath}${'?date_range=' + JSON.stringify(dateRange)}`,
      dataType: 'json',
      success: res => {
        setSalesByMerchant(res.salesByMerchant.map(item => (
          {
            category: truncateString(item.category, 20),
            gross_sales: item.gross_sales
          }
        )))

        setSalesBySubmerchant(res.salesBySubmerchant.map(item => (
          {
            category: truncateString(item.category, 20),
            gross_sales: item.gross_sales
          }
        )))

        setSalesByTicket(res.salesByTicket.map(item => (
          {
            category: truncateString(item.category, 20),
            gross_sales: item.gross_sales
          }
        )))

        setSalesByTerminal(res.salesByTerminal.map(item => (
          {
            category: truncateString(item.category, 20),
            gross_sales: item.gross_sales
          }
        )))

        setSalesByStatus(res.salesByStatus.map(item => (
          {
            category: truncateString(item.category, 20),
            gross_sales: item.gross_sales
          }
        )))
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  const onDateChange = ({ startDate, endDate }) => {
    if(startDate <= endDate) {
      setDateRange({
        ...dateRange,
        end_date: moment(endDate),
        start_date: moment(startDate)
      })
    }
  }

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.dashboard }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div className='card' style={{ height: '100%' }}>
        <div className='card-content'>
          <DateRangePicker
            displayFormat={() => "DD MMM YYYY"}
            endDate={ dateRange.end_date }
            endDateId="endDate"
            focusedInput={focusedInput}
            onDatesChange={onDateChange}
            onFocusChange={setFocusedInput}
            orientation={smallDevice ? 'vertical' : 'horizontal'}
            readOnly={ true }
            required={ true }
            noBorder={ true }
            startDate={ dateRange.start_date }
            startDateId="startDate"
            isOutsideRange={(date) => date > new Date() - 1 ? true : false }
            renderCalendarInfo={ ConfirmButton }
            minimumNights={ 0 }
            firstDayOfWeek={ 1 }
            hideKeyboardShortcutsPanel
            keepOpenOnDateSelect
          />
        </div>
      </div>


      <div className='card flex-box flex-row mt-10' style={{ height: '100%' }}>
        <div className='card-content flex-1'>
          <div className="card-header border-0">
            <div className="card-title m-0">
              { Website.translations.salesByMerchant }
            </div>
          </div>
          <div className='card-body'>
            { salesBySubmerchant.length > 0 ?
              <XYChart
                data={ salesBySubmerchant }
                seriesLabels={ [{ gross_sales: Website.translations.grossSales }] }
                xAxisTitle={ '' }
                yAxisTitle={ '' }
                columnWidth={ 15 }
                chartColors={ chartColors }
                chartKey={ "chartdiv1" }
              />
              :
              <NoData />
            }
          </div>
        </div>

        <div className='card-content flex-1'>
          <div className="card-header border-0">
            <div className="card-title m-0">
              { Website.translations.salesBySubmerchant }
            </div>
          </div>
          <div className='card-body'>
            { salesByMerchant.length > 0 ?
              <XYChart
                data={ salesByMerchant }
                seriesLabels={ [{ gross_sales: Website.translations.grossSales }] }
                xAxisTitle={ '' }
                yAxisTitle={ '' }
                columnWidth={ 15 }
                chartColors={ chartColors }
                chartKey={ "chartdiv2" }
              />
              :
              <NoData />
            }
          </div>
        </div>

        <div className='card-content flex-1'>
          <div className="card-header border-0">
            <div className="card-title m-0">
              { Website.translations.salesByTicket }
            </div>
          </div>
          <div className='card-body'>
            { salesByTicket.length > 0 ?
              <XYChart
                data={ salesByTicket }
                seriesLabels={ [{ gross_sales: Website.translations.grossSales }] }
                xAxisTitle={ '' }
                yAxisTitle={ '' }
                columnWidth={ 15 }
                chartColors={ chartColors }
                chartKey={ "chartdiv3" }
              />
              :
              <NoData />
            }
          </div>
        </div>
      </div>


      <div className='card flex-box flex-row mt-10' style={{ height: '100%' }}>
        <div className='card-content flex-1'>
          <div className="card-header border-0">
            <div className="card-title m-0">
              { Website.translations.salesByTerminal }
            </div>
          </div>
          <div className='card-body'>
            { salesByTerminal.length > 0 ?
              <XYChart
                data={ salesByTerminal }
                seriesLabels={ [{ gross_sales: Website.translations.grossSales }] }
                xAxisTitle={ '' }
                yAxisTitle={ '' }
                columnWidth={ 15 }
                chartColors={ chartColors }
                chartKey={ "chartdiv4" }
              />
              :
              <NoData />
            }
          </div>
        </div>

        <div className='card-content flex-1'>
          <div className="card-header border-0">
            <div className="card-title m-0">
              { Website.translations.salesByStatus }
            </div>
          </div>
          <div className='card-body'>
            { salesByStatus.length > 0 ?
              <SlicedChart
                data={ salesByStatus }
                chartColors={ chartColors }
                seriesLabels={ [{ gross_sales: Website.translations.grossSales }] }
                chartKey={ "chartdiv5" }
              />
              :
              <NoData />
            }
          </div>
        </div>
      </div>

      <div className='mt-10'>
        <DynamicEntityIndex
          initialItems={ reports }
          initialTotalPages={ 1 }
          disableDelete
          disableSearch
          disableCustomization={ true }
          entity={ "reports" }
          tabs={ [] }
          tableHeadings={ tableHeadings }
          itemRenderer={ itemRenderer }
          noResourcesTitle={ 'No Reports' }
          headline={ 'Reports' }
          hideHeadlineCount
          actions={ [] }
        />
      </div>

      { loading && <GlobalLoader/> }

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}
