import React, { useState } from 'react'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'
import SubmitButton from '../shared/SubmitButton'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'

export default function PromoContents({
  appProps, canCreate, canEdit, deletePath, disableDelete, initialTotalPages, items, itemsPath, createPath,
  merchantAccounts, selectedColumns, relevantMerchantAccountId, relevantSubaccountId, initialTotalCount
}) {
  const [showModal, setShowModal] = useState(false)
  const [selectedMerchantAccount, setSelectedMerchantAccount] = useState('-')
  const [promoName, setPromoName] = useState('')
  const [loading, setLoading] = useState(false)
  const [promoContents, setPromoContents] = useState(items)
  const [promoContentCount, setPromoCountentCount] = useState(initialTotalCount)

  const { flashMessage } = appProps

  const actions = []

  if (canEdit) {
    actions.push({
      url: item => item.itemPath,
      target: '_self',
      icon: 'fa-pencil'
    })
  }

  const handleMerchantAccountChange = e => {
    setSelectedMerchantAccount(e.target.value)
  }

  const handleCloseModal = () => {
    setPromoName('')
    setSelectedMerchantAccount('-')
    setShowModal(false)
  }

  const checkIfSubmitShouldBeDisabled = () => {
    return loading || !promoName.trim() || (merchantAccounts ? selectedMerchantAccount === '-' : false)
  }

  const handleSubmit = _e => {
    const fd = new FormData()

    fd.append('promo_content[name]', promoName)
    if (!!merchantAccounts) {
      fd.append('promo_content[merchant_account_id]', selectedMerchantAccount)
    } else if (relevantMerchantAccountId) {
      fd.append('promo_content[merchant_account_id]', relevantMerchantAccountId)
      
      if (relevantSubaccountId) {
        fd.append('promo_content[merchant_subaccount_promo_contents_attributes][0][merchant_subaccount_id]', relevantSubaccountId)
      }
    }

    setLoading(true)

    Rails.ajax({
      type: 'POST',
      url: createPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
        setLoading(false)
      }
    })
  }

  function renderPromoContent(promoContent, columns) {
    return (
      <>
        { columns.map(column => {
          const key = column.key
          let output

          if (key === 'active') {
            output = promoContent.active ? <i className='fa-light fa-circle-check'/> : <i className='fa-light fa-dash'/>
          } else if (key === 'name') {
            const firstImage =  promoContent.assets.find(el => el.mediaType.startsWith('image'))

            output = (
              <div className='product-image-wrapper'>
                { firstImage &&
                  <img className='mini-image' src={ firstImage.source } alt={ promoContent.name } />
                }
                <a href={ canEdit ? promoContent.itemPath : '#' } target={ canEdit ? '_self' : '' } >
                  { promoContent.name }
                </a>
              </div>
            )
          } else if (key === 'numberOfAssets') {
            output = promoContent.numberOfAssets || 0
          } else {
            output = promoContent[key] || '-'
          }

          return (
            <div key={ key } className='item flex-1'>
              <div>
                { output }
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.promoContent }
        breadcrumbs={ appProps.breadcrumbs }
        count={ promoContentCount }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ promoContents }
        setInitialItems={ setPromoContents }
        initialTotalPages={ initialTotalPages }
        selectAll
        disableDelete={ disableDelete }
        disableCustomization
        entity={ 'promo_contents' }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderPromoContent }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noPromoContents }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
        initialTotalCount={ initialTotalCount }
        updateTotalCount={ setPromoCountentCount }
      />

      { showModal &&
        <Modal
          visible
          closeModal={ handleCloseModal }
          mode={ merchantAccounts ? 'medium' : 'small' }
        >
          <FormInput
            label={ Website.translations.promoContentName }
            value={ promoName }
            onChange={ e => setPromoName(e.target.value) }
          />

          { !!merchantAccounts &&
            <FormInput
              label={ Website.translations.merchantAccount }
              value={ selectedMerchantAccount }
              onChange={ handleMerchantAccountChange }
              type='select'
              selectWithDefault
              selectEntities={ merchantAccounts }
              keyAttr='id'
              valueAttr='id'
              outputAttr='title'
            />
          }

          <div className='flex-box content-end m-t-30'>
            <SubmitButton
              value={ Website.translations.save }
              className='btn btn-primary'
              onSubmit={ handleSubmit }
              disabled={ checkIfSubmitShouldBeDisabled() }
            />
          </div>
        </Modal>
      }
    </>
  )
}
