import React, { useEffect } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5percent from "@amcharts/amcharts5/percent"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

export default function SlicedChart({ data, chartKey, seriesLabels, height, chartColors }){
  useEffect(() => {
    const root = am5.Root.new(chartKey)
    if(data) {
      start(root, data)
    }
    return () => root.dispose()
  }, [data])

  function start(root, data) {
    root.setThemes([
      am5themes_Animated.new(root)
    ])

    // Create chart
    let chart = root.container.children.push(
      am5percent.SlicedChart.new(root, {})
    );

    // Create series
    const series = chart.series.push(
      am5percent.FunnelSeries.new(root, {
        valueField: Object.keys(seriesLabels[0])[0],
        categoryField: "category",
        orientation: "horizontal", // or "vertical"
        bottomRatio: 1
      })
    );

    // Configure slices
    series.slices.template.setAll({
      tooltipText: "{category}: {value}",
      fillOpacity: 0.8,
    });

    const colorSet = am5.ColorSet.new(root, {
      colors: chartColors.map(color => am5.Color.fromString(color)),
      step: 1, // Use each color in sequence
    });

    series.set("colors", colorSet);

    series.slices.template.states.create("hover", {
      scale: 1.05,
    });

    // Add data
    series.data.setAll(data)

    series.appear(1000)
    chart.appear(1000, 100)
  }

  return(
    <div id={`${chartKey}`} style={{ width: '100%', height: height || '350px' }}></div>
  )
}
