import React, { useState, useEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

export default function XYChart({ data, chartTitle, xAxisTitle, yAxisTitle, yMin, yMax, chartColors, columnWidth, seriesLabels, height, chartKey }){

  useEffect(() => {
    const root = am5.Root.new(chartKey)
    if(data) {
      start(root, data)
    }
    return () => root.dispose()
  }, [data])

  function start(root, data) {
    root.setThemes([
      am5themes_Animated.new(root)
    ])

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout
    }))

    chart.children.unshift(am5.Label.new(root, {
      text: chartTitle,
      fontSize: 25,
      fontWeight: "500",
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 0,
      paddingBottom: 20
    }))

    chart.get("colors").set("colors", chartColors.map(color => am5.Color.fromString(color)))

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    )

    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 })
    xRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p50,
      rotation: -45
    })

    // xrenderer extra configuration
    // oversizedBehavior: "wrap",

    xRenderer.grid.template.setAll({
      location: 1
    })

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      categoryField: "category",
      renderer: xRenderer
    }))

    xAxis.children.push(am5.Label.new(root, {
      text: xAxisTitle,
      textAlign: 'center',
      x: am5.p50,
      fontWeight: 'bold'
    }))

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      }),
      min: yMin,
      max: yMax
    }))

    yAxis.children.push(am5.Label.new(root, {
      text: yAxisTitle,
      rotation: -90,
      textAlign: 'center',
      y: am5.p50,
      fontWeight: 'bold'
    }))

    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      x: 0,
      name: Object.values(seriesLabels[0])[0],
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: Object.keys(seriesLabels[0])[0],
      sequencedInterpolation: true,
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {})
    }))

    // chart.get("colors").set("colors", colors )

    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0, width: columnWidth || 40, tooltipText: "{name}: {valueY}", tooltipY: 0 })
    // series.columns.template.adapters.add("fill", function(fill, target) {
    //   return chart.get("colors").getIndex(series.columns.indexOf(target));
    // })

    series.columns.template.adapters.add("stroke", function(stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target))
    })

    series.data.setAll(data)
    legend.data.push(series)
    series.appear(1000)
    xAxis.data.setAll(data)
    chart.appear(1000, 100)
  }

  return(
    <div id={`${chartKey}`} style={{ width: '100%', height: height || '350px' }}></div>
  )
}
