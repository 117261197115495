import React, { useState, useEffect, useRef } from 'react'
import SaveFixedButton from '../shared/SaveFixedButton'
import LanguageHandler from '../shared/LanguageHandler'
import FormInput from '../shared/FormInput'
import MediaGallery from '../shared/MediaGallery'
import MediaUploader from '../shared/MediaUploader'
import ModalAlert from '../shared/ModalAlert'
import CreateButton from '../shared/CreateButton'

export default function Edit({
  appProps, currentLocation, locationGroups, updatePath, languages, mediaPath,
  validateFilePath, createMediaNoUploadPath, mediaGalleryProps
}) {
  const [location, setLocation]               = useState(currentLocation)
  const [saveButtonRef, setSaveButtonRef]     = useState(null)
  const [mediaUploader, setMediaUploader]     = useState(null)
  const [images, setImages]                   = useState(currentLocation.images)
  const [showUploadAlert, setShowUploadAlert] = useState(false)

  const usedLocales = location.translations.map(translation => translation.locale)
  const initialUsedLanguages = languages.filter(language => usedLocales.indexOf(language.code) > -1 )
  const currentLanguage = initialUsedLanguages.find(language => language.code === appProps.currentLocale) || initialUsedLanguages[0] || languages[0]

  const [usedLanguages, setUsedLanguages]         = useState(initialUsedLanguages.length ? initialUsedLanguages : [currentLanguage])
  const [selectedLanguage, setSelectedLanguage]   = useState(currentLanguage.code)
  const [usedLocationGroup, setUsedLocationGroup] = useState(currentLocation.locationGroupApiUid)
  const [translations, setTranslations]           = useState(() => getInitialTranslations(location))

  const didMountRef = useRef(false)
  const translatableContent = [
    { items: translations, setItems: setTranslations, initialValues: { title: '' } },
  ]

  useEffect( () => {
    if (didMountRef.current) {
      saveButtonRef.setSaveableStatus()
    } else {
      didMountRef.current = true
    }
  }, [images])

  function getInitialTranslations(item) {
    const initialTranslations = {}
    item.translations.map(translation => {
      initialTranslations[translation.locale] = {
        title: translation.title,
        id: translation.id
      }
    })

    return initialTranslations
  }

  const extraRemoveLanguageCallback = _locale => {
    saveButtonRef.setSaveableStatus()
  }

  const handleTitleChange = e => {
    const updatedTranslations = { ...translations }

    updatedTranslations[selectedLanguage].title = e.target.value
    setTranslations(updatedTranslations)
    saveButtonRef.setSaveableStatus()
  }

  const handleLocationGroupChange = e => {
    setUsedLocationGroup(e.target.value)
    saveButtonRef.setSaveableStatus()
  }

  function handleUploadImage(uploadedFile) {
    setImages( prevState => {
      let updatedImages = [ ...prevState ]
      const imageIndex = updatedImages.findIndex( image => image.uid === uploadedFile.uploadUid )
      if (imageIndex >= 0) {
        updatedImages.splice(imageIndex, 1, { ...uploadedFile, blob: null })
      }

      return updatedImages
    })
  }

  const save = () => {
    if (mediaUploader.isUploading()) {
      setShowUploadAlert(true)

    } else {
      const fd = new FormData()
      Object.keys(translations).forEach((locale, index) => {
        fd.append(`location[translations_attributes][${ index }][title]`, translations[locale].title || '')
        fd.append(`location[translations_attributes][${ index }][locale]`, locale)

        if (translations[locale].id) {
          fd.append(`location[translations_attributes][${ index }][id]`, translations[locale].id)
        }
        if (translations[locale]._destroy) {
          fd.append(`location[translations_attributes][${ index }][_destroy]`, translations[locale]._destroy)
        }
      })

      fd.append('location_group_api_uid', usedLocationGroup || null)
      fd.append('assets', JSON.stringify(
        images.filter( img => !img.blob ).map( img => ({
          id: img.id,
          media_id: img.mediaId,
          uid: img.uid
        }))
      ))

      saveButtonRef.setSavingStatus()

      Rails.ajax({
        type: 'PATCH',
        url: updatePath,
        dataType: 'json',
        data: fd,
        success: res => {
          saveButtonRef.setSuccessStatus()
          setLocation(res.location)
          setTranslations(getInitialTranslations(res.location))

          if (res.assets && res.assets.length) {
            setImages( prevState => prevState.map( image => ({ ...image, id: res.assets.find( item => item.uid === image.uid ).id }) ) )
          }
        },
        error: res => {
          saveButtonRef.setErrorStatusWithMessage(res.error)
        }
      })
    }
  }

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.location }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <LanguageHandler
        languages={ languages }
        usedLanguages={ usedLanguages }
        setUsedLanguages={ setUsedLanguages }
        selectedLanguage={ selectedLanguage }
        setSelectedLanguage={ setSelectedLanguage }
        translatableContent={ translatableContent }
        extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
      />

      <div className='card'>
        <FormInput
          label={ Website.translations.columns.title }
          value={translations[selectedLanguage]?.title || ''}
          onChange={handleTitleChange}
        />

        <div>
          { (locationGroups.length > 0)
            ? <FormInput
                label={ Website.translations.locationGroup }
                value={ usedLocationGroup || '-' }
                onChange={ handleLocationGroupChange }
                type='select'
                multiple={ false }
                selectWithDefault={ true }
                selectEntities={ locationGroups }
                keyAttr='apiUid'
                valueAttr='apiUid'
                outputFunc={ item => (item.translations.find(translation => translation.locale === appProps.currentLocale) || item.translations[0]).title }
              />
            : <div>
                <div className='fas-6 fw-semibold form-label mt-3'>{ Website.translations.locationGroup }</div>
                <div>{ Website.translations.noLocationGroups }</div>
              </div>
          }
        </div>

        <div className='m-t-15'>
          <MediaGallery
            multiple
            mediaTypes={ ['image'] }
            files={ images }
            setFiles={ setImages }
            onUploadMedia={ handleUploadImage }
            mediaPath={ mediaPath }
            mediaUploader={ mediaUploader }
            chooseMediaTitle={ Website.translations.media.images }
          />
        </div>
      </div>

      { showUploadAlert &&
        <ModalAlert
          alert={ Website.translations.media.uploadAlert }
          cancelText={ Website.translations.close }
          onClose={ () => setShowUploadAlert(false) }
        />
      }

      <MediaUploader
        ref={ ref => setMediaUploader(ref) }
        mediaPath={ mediaPath }
        validateFilePath={ validateFilePath }
        createMediaNoUploadPath={ createMediaNoUploadPath }
        mediaGalleryProps={ mediaGalleryProps }
      />

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.location }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
