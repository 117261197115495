import React, { useState, useEffect, useRef } from 'react'
import FormInput from '../shared/FormInput'
import SaveFixedButton from '../shared/SaveFixedButton'
import Modal from '../shared/Modal'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import MediaGallery from '../shared/MediaGallery'
import MediaUploader from '../shared/MediaUploader'
import ModalAlert from '../shared/ModalAlert'
import ColorPicker from '../shared/ColorPicker'
import CreateButton from '../shared/CreateButton'
import TextEditor from '../shared/TextEditor'
import LanguageHandler from '../shared/LanguageHandler'
import Toggle from 'react-toggle'

export default function EditMerchantSubaccount({
  appProps, merchantSubaccount, availableLanguages, updatePath,
  apiIntegrationsPath, deleteApiIntegrationPath, mediaPath,
  validateFilePath, createMediaNoUploadPath, mediaGalleryProps
}) {
  const [settings, setSettings]                                               = useState(merchantSubaccount.settings)
  const [links, setLinks]                                                     = useState(merchantSubaccount.links.map(link => ({...link, translations: [...link.translations, ...availableLanguages.reduce((acc, lang) => link.translations.find(item => item.locale === lang.code) ? acc : [...acc, { title: '', url: '', locale: lang.code }], [])]})))
  const [mid, setMid]                                                         = useState(merchantSubaccount.mid)
  const [internalName, setInternalName]                                       = useState(merchantSubaccount.internalName)
  const [saveButtonRef, setSaveButtonRef]                                     = useState(null)
  const [showModal, setShowModal]                                             = useState(false)
  const [integratedApis, setIntegratedApis]                                   = useState([])
  const [initialTotalPages, setInitialTotalPages]                             = useState(1)
  const [unusedApis, setUnusedApis]                                           = useState([])
  const [selectedColumns, setSelectedColumns]                                 = useState([])
  const [mediaUploader, setMediaUploader]                                     = useState(null)
  const [logo, setLogo]                                                       = useState(merchantSubaccount.logo)
  const [backgroundImage, setBackgroundImage]                                 = useState(merchantSubaccount.backgroundImage)
  const [receiptLogo, setReceiptLogo]                                         = useState(merchantSubaccount.receiptLogo)
  const [showUploadAlert, setShowUploadAlert]                                 = useState(false)
  const [colors, setColors]                                                   = useState(merchantSubaccount.colors)
  const [usedLanguages, setUsedLanguages]                                     = useState(merchantSubaccount.selectedLanguages)
  const [tokenizedLinkGeneration, setTokenizedLinkGeneration]                 = useState(merchantSubaccount.settings.find(setting => setting.key === 'tokenized_link_generation').value === 't')
  const [qrCodeGeneration, setQrCodeGeneration]                               = useState(merchantSubaccount.settings.find(setting => setting.key === 'qr_code_generation')?.value === 't')
  const [termsTranslations, setTermsTranslations]                             = useState(getInitialTranslations('terms_of_use'))
  const [privacyPolicyTranslations, setPrivacyPolicyTranslations]             = useState(getInitialTranslations('privacy_policy'))
  const currentLanguage                                                       = usedLanguages.length > 0 ? usedLanguages.find(language => language.code === appProps.currentLocale)?.code || usedLanguages[0].locale : []
  const [selectedLanguage, setSelectedLanguage]                               = useState(currentLanguage)
  const [handlerLanguages, setHandlerLanguages]                               = useState(availableLanguages)
  const [renderKey, setRenderKey]                                             = useState(1)
  const [additionalInfoTranslations, setAdditionalInfoTranslations]           = useState(getInitialTranslations('additional_information'))
  const [showAdditionalInfoUnderLogo, setShowAdditionalInfoUnderLogo]         = useState(merchantSubaccount.settings.find(setting => setting.key === 'show_additional_information_under_logo').value === 't')
  const [showAdditionalInfoOnReceipt, setShowAdditionalInfoOnReceipt]         = useState(merchantSubaccount.settings.find(setting => setting.key === 'show_additional_information_on_receipt').value === 't')
  const [additionalPrintInfoTranslations, setAdditionalPrintInfoTranslations] = useState(getInitialTranslations('additional_printing_information'))
  const [enableAdditionalPrintInfo, setEnableAdditionalPrintInfo]             = useState(merchantSubaccount.settings.find(setting => setting.key === 'additional_printing_information').value === 't')
  const [cutAdditionalPrintInfo, setCutAdditionalPrintInfo]                   = useState(merchantSubaccount.settings.find(setting => setting.key === 'cut_additional_printing_information').value === 't')
  const [billingSettings, setBillingSettings]                                 = useState({
    companyName: getBillingSetting('company_name'),
    address: getBillingSetting('address'),
    companyOccupation: getBillingSetting('company_occupation'),
    issuerVat: getBillingSetting('issuer_vat'),
    phone: getBillingSetting('phone'),
  })

  function getBillingSetting(key) {
    return merchantSubaccount.settings.find(setting => setting.key === key)?.value || ''
  }

  const { flashMessage } = appProps

  const didMountRef = useRef(false)
  const actions = [
    { url: item => `${apiIntegrationsPath}/${item.apiTitle}/edit`, icon: 'fa-pencil' }
  ]

  useEffect(() => {
    Rails.ajax({
      type: 'GET',
      url: apiIntegrationsPath,
      dataType: 'json',
      success: res => {
        setIntegratedApis(res.items)
        setInitialTotalPages(res.totalPages)
        setUnusedApis(res.unusedApis)
        setSelectedColumns(res.selectedColumns)
      }
    })
  }, [])

  useEffect( () => {
    if (didMountRef.current) {
      saveButtonRef.setSaveableStatus()
    } else {
      didMountRef.current = true
    }
  }, [logo, backgroundImage, receiptLogo, colors])

  useEffect(() => {
    setTermsTranslations(getInitialTranslations('terms_of_use'))
    setPrivacyPolicyTranslations(getInitialTranslations('privacy_policy'))
    setAdditionalPrintInfoTranslations(getInitialTranslations('additional_printing_information'))
    setAdditionalInfoTranslations(getInitialTranslations('additional_information'))
  }, [settings])

  function updateColor(type, color) {
    setColors( prevState => ({ ...prevState, [type]: color }) )
  }

  useEffect(() => {
    setRenderKey(Math.random())

    setLinks(prevState => prevState.map(link => ({ ...link, translations: link.translations.map(t => ({ ...t, title: t.title || link.translations.find(item => item.title !== '')?.title || '', url: t.url || link.translations.find(item => item.url !== '')?.url || '' }))})))
  }, [selectedLanguage])

  const translatableContent = [
    { items: termsTranslations, setItems: setTermsTranslations, initialValues: { title: '' } },
    { items: privacyPolicyTranslations, setItems: setPrivacyPolicyTranslations, initialValues: { title: '' } },
    { items: additionalPrintInfoTranslations, setItems: setAdditionalPrintInfoTranslations, initialValues: { title: '' } },
    { items: additionalInfoTranslations, setItems: setAdditionalInfoTranslations, initialValues: { title: '' } }
  ]

  const extraRemoveLanguageCallback = _locale => {
    saveButtonRef.setSaveableStatus()
  }

  function getInitialTranslations(key) {
    const initialTranslations = {}
    settings.find(setting => setting.key === key)?.translations?.map(translation => {
      initialTranslations[translation.locale] = {
        title: translation.title,
        id: translation.id
      }
    })

    return initialTranslations
  }

  const save = () => {
    if (mediaUploader.isUploading()) {
      setShowUploadAlert(true)

    } else {
      const fd = new FormData()
      fd.append('merchant_subaccount[mid]', mid)
      fd.append('merchant_subaccount[internal_name]', internalName)
      fd.append('assets', JSON.stringify(logo ? [{
        id: logo.id,
        media_id: logo.mediaId,
        uid: logo.uid
      }] : []))

      fd.append('background_image_assets', JSON.stringify(backgroundImage ? [{
        id: backgroundImage.id,
        media_id: backgroundImage.mediaId,
        uid: backgroundImage.uid
      }] : []))

      fd.append('receipt_logo_assets', JSON.stringify(receiptLogo ? [{
        id: receiptLogo.id,
        media_id: receiptLogo.mediaId,
        uid: receiptLogo.uid
      }] : []))

      fd.append('colors', JSON.stringify(colors))

      usedLanguages.forEach((language, index) => {
        if ((!language.id && !language._destroy) || language.id) {
          fd.append(`merchant_subaccount[selected_languages_attributes]${index}[locale]`, language.locale)
          if (language.id) {
            fd.append(`merchant_subaccount[selected_languages_attributes]${index}[id]`, language.id)
          }
          if (language._destroy) {
            fd.append(`merchant_subaccount[selected_languages_attributes]${index}[_destroy]`, language._destroy)
          }
        }
      })

      fd.append('tokenized_link_generation', tokenizedLinkGeneration.toString().slice(0, 1))
      fd.append('qr_code_generation', qrCodeGeneration.toString().slice(0, 1))

      Object.keys(billingSettings).forEach(settingKey => {
        const underscoreKey = settingKey.split(/\.?(?=[A-Z])/).join('_').toLowerCase()
        fd.append(underscoreKey, billingSettings[settingKey])
      })

      Object.keys(termsTranslations).forEach((locale, index) => {
        fd.append(`terms[translations_attributes][${ index }][title]`, termsTranslations[locale].title || '')
        fd.append(`terms[translations_attributes][${ index }][locale]`, locale)

        if (termsTranslations[locale].id) {
          fd.append(`terms[translations_attributes][${ index }][id]`, termsTranslations[locale].id)
        }
        if (termsTranslations[locale]._destroy) {
          fd.append(`terms[translations_attributes][${ index }][_destroy]`, termsTranslations[locale]._destroy)
        }
      })

      Object.keys(privacyPolicyTranslations).forEach((locale, index) => {
        fd.append(`privacy_policy[translations_attributes][${ index }][title]`, privacyPolicyTranslations[locale].title || '')
        fd.append(`privacy_policy[translations_attributes][${ index }][locale]`, locale)

        if (privacyPolicyTranslations[locale].id) {
          fd.append(`privacy_policy[translations_attributes][${ index }][id]`, privacyPolicyTranslations[locale].id)
        }
        if (privacyPolicyTranslations[locale]._destroy) {
          fd.append(`privacy_policy[translations_attributes][${ index }][_destroy]`, privacyPolicyTranslations[locale]._destroy)
        }
      })

      Object.keys(additionalPrintInfoTranslations).forEach((locale, index) => {
        fd.append(`additional_printing_information[translations_attributes][${ index }][title]`, additionalPrintInfoTranslations[locale].title || '')
        fd.append(`additional_printing_information[translations_attributes][${ index }][locale]`, locale)

        if (additionalPrintInfoTranslations[locale].id) {
          fd.append(`additional_printing_information[translations_attributes][${ index }][id]`, additionalPrintInfoTranslations[locale].id)
        }
        if (additionalPrintInfoTranslations[locale]._destroy) {
          fd.append(`additional_printing_information[translations_attributes][${ index }][_destroy]`, additionalPrintInfoTranslations[locale]._destroy)
        }
      })

      Object.keys(additionalInfoTranslations).forEach((locale, index) => {
        fd.append(`additional_information[translations_attributes][${ index }][title]`, additionalInfoTranslations[locale].title || '')
        fd.append(`additional_information[translations_attributes][${ index }][locale]`, locale)

        if (additionalInfoTranslations[locale].id) {
          fd.append(`additional_information[translations_attributes][${ index }][id]`, additionalInfoTranslations[locale].id)
        }
        if (additionalInfoTranslations[locale]._destroy) {
          fd.append(`additional_information[translations_attributes][${ index }][_destroy]`, additionalInfoTranslations[locale]._destroy)
        }
      })

      fd.append('show_additional_information_under_logo', showAdditionalInfoUnderLogo)
      fd.append('show_additional_information_on_receipt', showAdditionalInfoOnReceipt)
      fd.append('additional_printing_information[value]', enableAdditionalPrintInfo)
      fd.append('cut_additional_printing_information', cutAdditionalPrintInfo)

      links.forEach((link, index) => {
        if (link.id > 0) {
          fd.append(`merchant_subaccount[links_attributes][${ index }][id]`, link.id)
        }
        if (link._destroy) {
          fd.append(`merchant_subaccount[links_attributes][${ index }][_destroy]`, true)
        } else {
          link.translations.forEach((t, idx) => {
            if (t.id > 0) {
              fd.append(`merchant_subaccount[links_attributes][${ index }][translations_attributes][${ idx }][id]`, t.id)
            }

            if (t.id > 0 && (t.title === '' || t.url === '')) {
              fd.append(`merchant_subaccount[links_attributes][${ index }][translations_attributes][${ idx }][_destroy]`, true)
            } else {
              if (t.title !== '' && t.url !== '') {
                fd.append(`merchant_subaccount[links_attributes][${ index }][translations_attributes][${ idx }][locale]`, t.locale)
                fd.append(`merchant_subaccount[links_attributes][${ index }][translations_attributes][${ idx }][title]`, t.title)
                fd.append(`merchant_subaccount[links_attributes][${ index }][translations_attributes][${ idx }][url]`, t.url)
              }
            }
          })
        }
      })

      saveButtonRef.setSavingStatus()

      Rails.ajax({
        type: 'PATCH',
        url: updatePath,
        dataType: 'json',
        data: fd,
        success: res => {
          saveButtonRef.setSuccessStatus()
          setSettings(res.settings)
          setLinks(res.links.map(link => ({...link, translations: [...link.translations, ...availableLanguages.reduce((acc, lang) => link.translations.find(item => item.locale === lang.code) ? acc : [...acc, { title: '', url: '', locale: lang.code }], [])]})))
          setMid(res.mid)
          setUsedLanguages(res.selectedLanguages)

          if (res.assets && res.assets.length) {
            setLogo({ ...logo, id: res.assets[0].id })
          }

          if (res.backgroundImageAssets && res.backgroundImageAssets.length) {
            setBackgroundImage({ ...backgroundImage, id: res.backgroundImageAssets[0].id })
          }

          if (res.receiptLogoAssets && res.receiptLogoAssets.length) {
            setReceiptLogo({ ...receiptLogo, id: res.receiptLogoAssets[0].id })
          }

          if (res.redirectUrl) {
            location.href = res.redirectUrl
          }
        },
        error: res => {
          saveButtonRef.setErrorStatusWithMessage(res.error)
        }
      })
    }
  }

  const handleClick = (api, _e) => {
    const fd = new FormData()
    fd.append('api', api)

    Rails.ajax({
      type: 'POST',
      url: apiIntegrationsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.location) {
          location.href = res.location
        }
      }
    })
  }

  const handleCheckBoxChange = (e, code) => {
    const codeIndex = usedLanguages.findIndex(language => language.locale === code)
    if (e.target.checked) {
      if (codeIndex !== -1) {
        setUsedLanguages(prevState => [
          ...usedLanguages.slice(0, codeIndex),
          { ...usedLanguages[codeIndex], _destroy: false },
          ...usedLanguages.slice(codeIndex + 1)
        ])
      } else {
        setUsedLanguages(prevState => [...prevState, { locale: code }])
      }
    } else {
      if (usedLanguages.filter(language => !language._destroy).length === 1) {
        flashMessage.show(Website.translations.minimumLanguages, 'notice')
        return
      } else {
        setUsedLanguages(prevState => [
          ...prevState.slice(0, codeIndex),
          { ...usedLanguages[codeIndex], _destroy: true },
          ...prevState.slice(codeIndex + 1)
        ])
      }
    }

    saveButtonRef.setSaveableStatus()
  }

  function renderApi(api, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        return (
          <div key={ column.key } className='item flex-1'>
            <div>
              { api[key] || '-' }
            </div>
          </div>
        )
      })}
    </>
    )
  }

  const handleBillingSettingChange = (e, key) => {
    saveButtonRef.setSaveableStatus()

    setBillingSettings(prevState => ({ ...prevState, [key]: e.target.value }))
  }

  const handleAddLink = () => {
    const newLink = {
      id: Math.min(...links.map(el => el.id), 0) - 1,
      translations: availableLanguages.map(lang => {
        return {
          title: '',
          url: '',
          locale: lang.code
        }
      })
    }

    saveButtonRef.setSaveableStatus()

    setLinks(prevState =>  [...prevState, newLink])
  }

  const handleDeleteLink = linkId => {
    const relevantLinkIndex = links.findIndex(el => el.id === linkId)

    saveButtonRef.setSaveableStatus()

    if (linkId < 0) {
      setLinks(prevState => [
        ...prevState.slice(0, relevantLinkIndex),
        ...prevState.slice(relevantLinkIndex + 1)
      ])
    } else {
      setLinks(prevState => [
        ...prevState.slice(0, relevantLinkIndex),
        {
          ...prevState[relevantLinkIndex],
          _destroy: true
        },
        ...prevState.slice(relevantLinkIndex + 1)
      ])
    }
  }

  const handleChangeLinkField = (field, linkId, value) => {
    const relevantLinkIndex = links.findIndex(el => el.id === linkId)

    saveButtonRef.setSaveableStatus()

    setLinks(prevState => [
      ...prevState.slice(0, relevantLinkIndex),
      {
        ...prevState[relevantLinkIndex],
        translations: prevState[relevantLinkIndex].translations.map(item => item.locale === selectedLanguage ? { ...item, [field]: value } : item)
      },
      ...prevState.slice(relevantLinkIndex + 1)
    ])
  }

  let ferryType = merchantSubaccount.settings.find(el => el.key === 'stock_type')?.value === 'Stock::Ferry'

  return (
    <>
      <CreateButton
        pageTitle={ merchantSubaccount.internalName ? merchantSubaccount.internalName : merchantSubaccount.mid }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <LanguageHandler
        languages={ availableLanguages }
        usedLanguages={ handlerLanguages }
        setUsedLanguages={ setHandlerLanguages }
        selectedLanguage={ selectedLanguage }
        setSelectedLanguage={ setSelectedLanguage }
        translatableContent={ translatableContent }
        extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
      />

      <div className='card m-b-30'>
        <FormInput
          label={ Website.translations.editMerchantId }
          value={ mid }
          onChange={ e => { saveButtonRef.setSaveableStatus(); setMid(e.target.value) } }
        />

        <FormInput
          label={ Website.translations.columns.title }
          value={ internalName }
          onChange={ e => { saveButtonRef.setSaveableStatus(); setInternalName(e.target.value) } }
        />
      </div>

      <div className='card m-b-30'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.logo } / { Website.translations.receiptLogo } / { Website.translations.backgroundImage }
          </div>
        </div>
        <div className="card-body">
          <div className='flex-box'>
            <div className='m-r-20'>
              <div className='m-t-10'>
                { Website.translations.logo }
              </div>

              <MediaGallery
                mediaTypes={ ['image'] }
                files={ logo ? [logo] : [] }
                setFiles={ files => setLogo(files.length ? files[0] : null) }
                onUploadMedia={ uploadedFile => setLogo({ ...uploadedFile, blob: null }) }
                mediaPath={ mediaPath }
                mediaUploader={ mediaUploader }
                chooseMediaTitle={ Website.translations.chooseLogo }
              />
            </div>

            <div className='m-r-20'>
              <div className='m-t-10'>
                { Website.translations.receiptLogo }
              </div>

              <MediaGallery
                mediaTypes={ ['image'] }
                files={ receiptLogo ? [receiptLogo] : [] }
                setFiles={ files => setReceiptLogo(files.length ? files[0] : null) }
                onUploadMedia={ uploadedFile => setReceiptLogo({ ...uploadedFile, blob: null }) }
                mediaPath={ mediaPath }
                mediaUploader={ mediaUploader }
                chooseMediaTitle={ Website.translations.chooseLogo }
              />
            </div>

            <div className='m-r-20'>
              <div className='m-t-10' style={{ whiteSpace: 'nowrap' }}>
                { Website.translations.backgroundImage }
              </div>

              <MediaGallery
                mediaTypes={ ['image'] }
                files={ backgroundImage ? [backgroundImage] : [] }
                setFiles={ files => setBackgroundImage(files.length ? files[0] : null) }
                onUploadMedia={ uploadedFile => setBackgroundImage({ ...uploadedFile, blob: null }) }
                mediaPath={ mediaPath }
                mediaUploader={ mediaUploader }
                chooseMediaTitle={ Website.translations.media.chooseImage }
              />
            </div>
          </div>
        </div>
      </div>

      <div className='card m-b-30'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.colors }
          </div>
        </div>
        <div className="card-body">
          <div className='flex-box flex-wrap'>
            <div className='flex-box items-center flex-1'>
              <ColorPicker
                color={ colors.colorScheme || '' }
                setColor={ color => updateColor('colorScheme', color) }
              />

              <div className='m-l-10'>
                { Website.translations.colorScheme }
              </div>
            </div>

            <div className='flex-box items-center flex-1'>
              <ColorPicker
                color={ colors.buttonBackground || '' }
                setColor={ color => updateColor('buttonBackground', color) }
              />

              <div className='m-l-10'>
                { Website.translations.buttonBackground }
              </div>
            </div>

            <div className='flex-box items-center flex-1'>
              <ColorPicker
                color={ colors.buttonText || '' }
                setColor={ color => updateColor('buttonText', color) }
              />

              <div className='m-l-10'>
                { Website.translations.buttonText }
              </div>
            </div>

            <div className='flex-box items-center flex-1'>
              <ColorPicker
                color={ colors.buttonBorder || '' }
                setColor={ color => updateColor('buttonBorder', color) }
              />

              <div className='m-l-10'>
                { Website.translations.buttonBorder }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card m-b-30'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.activeLanguages }
          </div>
        </div>
        <div className="card-body">
          <div>
            <table style={{ width: '100%' }}>
              <thead style={{ width: '100%' }}>
                <tr>
                  <th style={{ width: '50%', padding: '20px 10px' }}>Language</th>
                  <th style={{ width: '50%', padding: '20px 10px' }} className='flex-box content-end'>{ Website.translations.active }</th>
                </tr>
              </thead>
              <tbody style={{ width: '100%' }}>
                { availableLanguages.map( (language, index) => (
                  <tr style={{ width: '100%' }} key={ index }>
                    <td style={{ width: '50%', padding: '20px 10px' }}>{ language.presentation }</td>
                    <td style={{ width: '50%', padding: '20px 10px' }} className='flex-box content-end'>
                      <input
                        style={{ height: '100%' }}
                        type='checkbox'
                        checked={ !!usedLanguages.find(usedLanguage => usedLanguage.locale === language.code && !usedLanguage._destroy) }
                        onChange={ e => handleCheckBoxChange(e, language.code) }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='card m-b-30 m-t-30'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.terms }
          </div>
        </div>
        <div className="card-body">
          <div>
            <TextEditor
              key={ renderKey }
              content={ termsTranslations[selectedLanguage]?.title || '' }
              setContent={ html => { setTermsTranslations(prevState => ({ ...prevState, [selectedLanguage]: { title: html, id: prevState[selectedLanguage]?.id } })); saveButtonRef.setSaveableStatus() } }
            />
          </div>
        </div>
      </div>

      <div className='card m-b-30 m-t-30'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.privacyPolicy }
          </div>
        </div>
        <div className="card-body">
          <div>
            <TextEditor
              key={ renderKey }
              content={ privacyPolicyTranslations[selectedLanguage]?.title || '' }
              setContent={ html => { setPrivacyPolicyTranslations(prevState => ({ ...prevState, [selectedLanguage]: { title: html, id: prevState[selectedLanguage]?.id } })); saveButtonRef.setSaveableStatus() } }
            />
          </div>
        </div>
      </div>

      <div className='card m-b-30 m-t-30'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.billingSettings }
          </div>
        </div>
        <div className="card-body">
          <div>
            <FormInput
              label={ Website.translations.companyName }
              value={ billingSettings.companyName }
              onChange={ e => handleBillingSettingChange(e, 'companyName') }
            />

            <FormInput
              label={ Website.translations.address }
              value={ billingSettings.address }
              onChange={ e => handleBillingSettingChange(e, 'address') }
              placeholder={ Website.translations.addressPlaceholder }
            />

            <FormInput
              label={ Website.translations.companyOccupation }
              value={ billingSettings.companyOccupation }
              onChange={ e => handleBillingSettingChange(e, 'companyOccupation') }
            />

            <FormInput
              label={ Website.translations.issuerVat }
              value={ billingSettings.issuerVat }
              onChange={ e => handleBillingSettingChange(e, 'issuerVat') }
            />

            <FormInput
              label={ Website.translations.columns.phone }
              value={ billingSettings.phone }
              onChange={ e => handleBillingSettingChange(e, 'phone') }
            />
          </div>
        </div>
      </div>

      <div className='card m-b-30 m-t-30'>
        <div className="card-header border-0">
          <div className="card-title m-0 flex-box items-center content-space-between" style={{ width: '100%' }}>
            { Website.translations.apiIntegrations }

            { unusedApis.length > 0 &&
              <button
                className="btn btn-primary"
                onClick={ () => setShowModal(true) }
              >
                { Website.translations.create }
              </button>
            }
          </div>

        </div>
        <div className="card-body">
          <div>
            { selectedColumns.length > 0 &&
              <DynamicEntityIndex
                appProps={ appProps }
                initialItems={ integratedApis }
                initialTotalPages={ initialTotalPages }
                selectAll={ true }
                disableCustomization={ true }
                entity={ 'apis' }
                tabs={ [] }
                tableHeadings={ selectedColumns }
                itemRenderer={ renderApi }
                itemsPath={ apiIntegrationsPath }
                deletePath={ deleteApiIntegrationPath }
                noResourcesTitle={ Website.translations.noApiIntegrations }
                actions={ actions }
                columnTranslations={ Website.translations.columns }
              />
            }
          </div>
        </div>
      </div>

      <div className='card m-b-30 m-t-30'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.advancedSettings }
          </div>
        </div>
        <div className="card-body">
          <div className='m-b-50'>
            <FormInput
              label={ Website.translations.additionalInfo }
              value={ additionalInfoTranslations[selectedLanguage]?.title || '' }
              onChange={ e => { saveButtonRef.setSaveableStatus(); setAdditionalInfoTranslations(prevState => ({ ...prevState, [selectedLanguage]: { ...prevState[selectedLanguage], title: e.target.value } })) } }
            />

            <div className="flex-box items-center content-space-between m-t-20">
              <label className='fs-6 fw-semibold form-label mt-3'>
                { Website.translations.showUnderLogo }
              </label>
              <Toggle
                icons={{ unchecked: null }}
                className='react-toggle small'
                checked={ showAdditionalInfoUnderLogo }
                onChange={ _e => { saveButtonRef.setSaveableStatus(); setShowAdditionalInfoUnderLogo(prevState => !prevState) } }
              />
            </div>
            { !ferryType &&
              <div className="flex-box items-center content-space-between m-t-20">
                <label className='fs-6 fw-semibold form-label mt-3'>
                  { Website.translations.showOnReceipt }
                </label>
                <Toggle
                  icons={{ unchecked: null }}
                  className='react-toggle small'
                  checked={ showAdditionalInfoOnReceipt }
                  onChange={ _e => { saveButtonRef.setSaveableStatus(); setShowAdditionalInfoOnReceipt(prevState => !prevState) } }
                />
              </div>
            }
          </div>

          <hr />

          { !ferryType &&
            <React.Fragment>
              <div className="flex-box items-center content-space-between m-b-50" style={{ flexWrap: 'wrap', gap: '30px' }}>
                <label className='fs-6 fw-semibold form-label mt-3'>
                  { Website.translations.additionalPrintInfo }
                </label>
                <div>
                  <Toggle
                    icons={{ unchecked: null }}
                    className='react-toggle small'
                    checked={ enableAdditionalPrintInfo }
                    onChange={ _e => { saveButtonRef.setSaveableStatus(); setEnableAdditionalPrintInfo(prevState => !prevState) } }
                  />
                </div>
                { enableAdditionalPrintInfo &&
                  <>
                    <div style={{ width: '100%' }}>
                      <FormInput
                        value={ additionalPrintInfoTranslations[selectedLanguage]?.title || '' }
                        onChange={ e => { setAdditionalPrintInfoTranslations(prevState => ({ ...prevState, [selectedLanguage]: { title: e.target.value, id: prevState[selectedLanguage]?.id } })); saveButtonRef.setSaveableStatus() } }
                      />
                    </div>
                    <div className="flex-box items-center content-space-between" style={{ width: '100%' }}>
                      <label className='fs-6 fw-semibold form-label mt-3'>
                        { Website.translations.cutInfoWhilePrinting }
                      </label>
                      <div>
                        <Toggle
                          icons={{ unchecked: null }}
                          className='react-toggle small'
                          checked={ cutAdditionalPrintInfo }
                          onChange={ _e => { saveButtonRef.setSaveableStatus(); setCutAdditionalPrintInfo(prevState => !prevState) } }
                        />
                      </div>
                    </div>
                  </>
                }
              </div>

              <hr />

              <div className="flex-box items-center content-space-between m-b-50">
                <label className='fs-6 fw-semibold form-label mt-3'>
                  { Website.translations.tokenizedLinkGeneration }
                </label>
                <div>
                  <Toggle
                    icons={{ unchecked: null }}
                    className='react-toggle small'
                    checked={ tokenizedLinkGeneration }
                    onChange={ _e => { saveButtonRef.setSaveableStatus(); setTokenizedLinkGeneration(prevState => !prevState) } }
                  />
                </div>
              </div>

              <hr />

              <div className="flex-box items-center content-space-between">
                <label className='fs-6 fw-semibold form-label mt-3'>
                  { Website.translations.qrCodeGeneration }
                </label>
                <div>
                  <Toggle
                    icons={{ unchecked: null }}
                    className='react-toggle small'
                    checked={ qrCodeGeneration }
                    onChange={ _e => { saveButtonRef.setSaveableStatus(); setQrCodeGeneration(prevState => !prevState) } }
                  />
                </div>
              </div>
            </React.Fragment>
          }
        </div>
      </div>

      <div className='card m-b-30 m-t-30'>
        <div className="card-header border-0">
          <div className="card-title m-0">
            { Website.translations.links }
          </div>
        </div>

        <div className='card-body'>
          <div>
            { links.filter(el => !el._destroy).map((link, idx) => (
              <div
                key={ idx }
                className='flex-box items-center content-space-between'
              >
                <div className='flex-box' style={{ gap: '10px', width: '80%' }}>
                  <FormInput
                    label='title'
                    value={ link.translations.find(el => el.locale === selectedLanguage)?.title }
                    onChange={ e => handleChangeLinkField('title', link.id, e.target.value) }
                    classes='flex-1'
                  />

                  <FormInput
                    label='url'
                    value={ link.translations.find(el => el.locale === selectedLanguage)?.url }
                    onChange={ e => handleChangeLinkField('url', link.id, e.target.value) }
                    classes='flex-1'
                  />
                </div>

                <div
                  className='flex-box items-center'
                  style={{ cursor: 'pointer' }}
                  onClick={ _e => handleDeleteLink(link.id) }
                >
                  <i
                    className='fa-light fa-trash'
                    style={{ fontSize: '16px' }}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className='flex-box content-end m-t-50' style={{ width: '100%' }}>
            <button
              className='btn btn-primary'
              onClick={ handleAddLink }
              style={{ height: '40px' }}
            >
              { Website.translations.addLink }
            </button>
          </div>
        </div>
      </div>

      { showModal &&
        <Modal
          visible={ true }
          closeModal={ () => setShowModal(false) }
          mode="large"
        >
          <div>
            <h3>
              { Website.translations.options }
            </h3>
            <div className="row g-6 g-xl-9 content-space-around" >
              { unusedApis.map((api, index) => (
                <div key={ index } className="col-md-6 col-xl-4">
                  <button
                    type='button'
                    className="card border-hover-primary m-auto"
                    onClick={ e => handleClick(api, e) }
                  >
                    <div className="card-header border-0 pt-9">
                      <div className="card-title m-0">
                        <div className="symbol symbol-50px w-50px bg-light">
                          <i style={{ fontSize: 50 }} className={`fa-solid fa-bars`} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-9">
                      <div className="fs-3 fw-bold text-dark">{ api }</div>
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      }

      { showUploadAlert &&
        <ModalAlert
          alert={ Website.translations.media.uploadAlert }
          cancelText={ Website.translations.close }
          onClose={ () => setShowUploadAlert(false) }
        />
      }

      <MediaUploader
        ref={ ref => setMediaUploader(ref) }
        mediaPath={ mediaPath }
        validateFilePath={ validateFilePath }
        createMediaNoUploadPath={ createMediaNoUploadPath }
        mediaGalleryProps={ mediaGalleryProps }
      />

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.merchantSubaccount }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
